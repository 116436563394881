import clsx from 'clsx';
import { Fragment } from 'react';

import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import RichText, { RichTextProps } from '@/components/shared/RichText';

export interface WorkflowProps {
  backgroundColor?: 'light' | 'dark';
  title: string;
  subtitle: string;
  text: RichTextProps[];
  anchor: string;
  item?: WorkflowItemProps[];
}

export interface WorkflowItemProps {
  title?: string;
  description: string;
}

const Workflow = ({
  backgroundColor = 'light',
  title,
  subtitle,
  text,
  anchor,
  item,
}: WorkflowProps) => {
  return (
    <GlobalWrapperSection
      classNameWrapper={clsx(
        backgroundColor === 'light' && 'bg-collapo-white',
        backgroundColor === 'dark' && 'bg-collapo-black-10',
      )}
      classNameInner="flex-wrap justify-center xl:px-10"
      enabledDesktopPaddingX={false}
      anchor={anchor}
    >
      <Fragment>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              {subtitle && (
                <span className="mb-2 block text-h6 font-semi-bold uppercase text-collapo-blue">
                  {subtitle}
                </span>
              )}
              {title && (
                <h2 className="mb-10 mt-8 text-h2 font-bold text-collapo-black sm:text-h2-mobile md:text-[40px]">
                  {title}
                </h2>
              )}
              {text.length > 0 && (
                <div className="flex flex-wrap justify-center gap-y-6">
                  <RichText
                    isCookieBar
                    items={text}
                    parentSection="ourTeam"
                    parentIndex={1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {item && item.length > 0 && (
          <div className="-mx-4 flex flex-wrap">
            {item?.map((itemWorkflow, index) => {
              const isFirst = index === 0;
              const isLast = index === item.length - 1;

              return (
                <Fragment key={`workflow-${index}`}>
                  <div className="w-full px-4 md:w-1/2 xl:w-1/4">
                    <div className="relative mx-auto mb-10 max-w-[370px] text-center">
                      {isFirst && (
                        <div className="absolute -right-4 top-12 hidden h-1 w-16 border-t-2 border-dashed border-collapo-blue xl:block 2xl:w-[75px]"></div>
                      )}
                      {!isFirst && !isLast && (
                        <>
                          <div className="absolute -left-4 top-12 hidden h-1 w-16 border-t-2 border-dashed border-collapo-blue xl:block 2xl:w-[75px]"></div>
                          <div className="absolute right-[-14px] top-12 hidden h-1 w-16 border-t-2 border-dashed border-collapo-blue xl:block 2xl:w-[75px]"></div>
                        </>
                      )}
                      {isLast && (
                        <div className="absolute left-[-14px] top-12 hidden h-1 w-16 border-t-2 border-dashed border-collapo-blue xl:block 2xl:w-[75px]"></div>
                      )}
                      <div className="relative mx-auto mb-8 flex size-[100px] items-center justify-center rounded-m bg-collapo-blue text-collapo-white">
                        <svg
                          width="44"
                          height="44"
                          viewBox="0 0 44 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-current"
                        >
                          <g clip-path="url(#clip0_1697_2534)">
                            <path
                              d="M31.8312 24.2688H12.1687C11.6875 24.2688 11.2062 24.475 10.9312 24.8875C10.6562 25.3 10.5187 25.7813 10.6562 26.2625C12.1 31.35 16.775 34.925 22 34.925C27.3625 34.925 31.9 31.4875 33.3437 26.2625C33.4812 25.7813 33.4125 25.3 33.0687 24.8875C32.7937 24.475 32.3125 24.2688 31.8312 24.2688ZM22 31.7625C18.8375 31.7625 16.0187 30.0438 14.4375 27.3625H29.5625C28.05 30.0438 25.2312 31.7625 22 31.7625Z"
                              fill="white"
                            ></path>
                            <path
                              d="M22 0.550049C10.175 0.550049 0.549988 10.175 0.549988 22C0.549988 33.825 10.175 43.5188 22.0687 43.5188C33.9625 43.5188 43.5875 33.8938 43.5875 22C43.5875 10.1063 33.825 0.550049 22 0.550049ZM22 40.425C11.825 40.425 3.57499 32.175 3.57499 22C3.57499 11.825 11.8937 3.6438 22 3.6438C32.1062 3.6438 40.425 11.8938 40.425 22.0688C40.425 32.2438 32.175 40.425 22 40.425Z"
                              fill="white"
                            ></path>
                            <path
                              d="M13.75 18.425C15.2688 18.425 16.5 17.1938 16.5 15.675C16.5 14.1563 15.2688 12.925 13.75 12.925C12.2312 12.925 11 14.1563 11 15.675C11 17.1938 12.2312 18.425 13.75 18.425Z"
                              fill="white"
                            ></path>
                            <path
                              d="M30.25 18.425C31.7688 18.425 33 17.1938 33 15.675C33 14.1563 31.7688 12.925 30.25 12.925C28.7312 12.925 27.5 14.1563 27.5 15.675C27.5 17.1938 28.7312 18.425 30.25 18.425Z"
                              fill="white"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1697_2534">
                              <rect width="44" height="44" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h3 className="mb-5 text-h5 font-semi-bold text-collapo-black">
                        {itemWorkflow.title}
                      </h3>
                      <p className="text-p text-collapo-black-80">
                        {itemWorkflow.description}
                      </p>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </Fragment>
    </GlobalWrapperSection>
  );
};
export default Workflow;

import Workflow, { WorkflowProps } from '@/components/Workflow/Workflow';

const DynamicWorkflow = ({
  backgroundColor,
  title,
  subtitle,
  text,
  anchor,
  item,
}: WorkflowProps) => {
  return (
    <Workflow
      backgroundColor={backgroundColor}
      title={title}
      subtitle={subtitle}
      text={text}
      anchor={anchor}
      item={item}
    />
  );
};

export default DynamicWorkflow;

import clsx from 'clsx';
import { CldImage, CldVideoPlayer, getCldImageUrl } from 'next-cloudinary';
import { useEffect, useRef, useState } from 'react';

import { videoStreamingProfilesType } from '@/types/videoStreamingProfilesType';

import HashBlurImage from '../HashBlurImage/HashBlurImage';

export interface VideoProps {
  posterAlt: string;
  posterSrc: string;
  videoSrc: string;
  parentSection?: string;
  parentIndex?: number;
  autoplay?: boolean;
  blurHash?: string;
  className?: string;
  classNameBlurHash?: string;
  controls?: boolean;
  enabledLazyLoading?: boolean;
  fullscreen?: boolean;
  height?: number;
  loop?: boolean;
  muted?: boolean;
  pauseVideo?: boolean;
  width?: number;
  disablePlayPromise?: boolean;
  streamingProfile?: videoStreamingProfilesType;
  showOriginalSource?: boolean;
}

const Video = ({
  videoSrc = '',
  posterSrc = '',
  posterAlt = '',
  className,
  classNameBlurHash,
  parentSection,
  parentIndex,
  enabledLazyLoading = true,
  autoplay = true,
  muted = true,
  controls = false,
  loop = true,
  blurHash,
  width = 1920,
  height = 1080,
  fullscreen = true,
  pauseVideo = false,
  disablePlayPromise,
  streamingProfile = 'full_hd',
  showOriginalSource = false,
}: VideoProps) => {
  const [completeLoadingImage, setCompleteLoadingImage] = useState(false);
  const [completeLoadingVideo, setCompleteLoadingVideo] = useState(false);
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const videoElement = videoPlayerRef.current;
    if (!videoElement) return;

    const handleContextMenu = (event: MouseEvent) => {
      event.stopPropagation();
    };

    videoElement.addEventListener('contextmenu', handleContextMenu);
    return () =>
      videoElement.removeEventListener('contextmenu', handleContextMenu);
  }, [videoPlayerRef]);

  useEffect(() => {
    if (!disablePlayPromise) {
      const videoPlayer = videoPlayerRef.current;

      if (!videoPlayer || !completeLoadingVideo) {
        return;
      }

      const playPromise = pauseVideo ? videoPlayer.pause() : videoPlayer.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    }
  }, [pauseVideo, videoPlayerRef, completeLoadingVideo]);

  return (
    <div className={clsx('relative w-full items-center', className)}>
      <div
        className={clsx(
          'flex h-full flex-col',
          fullscreen && 'cldVideoPlayer-wrapper',
        )}
      >
        <CldVideoPlayer
          id={`collapo-video-player-${parentSection}-${parentIndex}`}
          videoRef={videoPlayerRef}
          width={width}
          height={height}
          src={videoSrc}
          autoplay={autoplay ? 'on-scroll' : false}
          loop={loop}
          playsinline
          controls={controls}
          muted={muted}
          preload={autoplay ? 'auto' : 'metadata'}
          {...(!showOriginalSource && {
            transformation: { streaming_profile: streamingProfile },
          })}
          {...(!showOriginalSource && { sourceTypes: ['hls'] })}
          logo={false}
          poster={getCldImageUrl({
            src: posterSrc,
            width: width,
            height: height,
          })}
          className={clsx(
            'size-full overflow-hidden object-cover',
            controls && 'z-[2]',
          )}
          onDataLoad={() => setCompleteLoadingVideo(true)}
        />
      </div>
      <div
        className={clsx(
          'pointer-events-none absolute left-0 top-0 size-full transition-opacity duration-0 motion-reduce:transition-none',
          completeLoadingImage && !completeLoadingVideo
            ? 'opacity-100'
            : 'opacity-0',
          completeLoadingImage && completeLoadingVideo
            ? 'opacity-0'
            : 'opacity-100',
        )}
      >
        <CldImage
          src={posterSrc}
          alt={posterAlt}
          className={clsx(
            'w-full object-cover transition-all duration-300 motion-reduce:transition-none',
          )}
          sizes="100vw"
          fill
          loading={enabledLazyLoading ? 'lazy' : 'eager'}
          onLoad={() => setCompleteLoadingImage(true)}
        />
      </div>
      <HashBlurImage
        blurHash={blurHash}
        className={clsx(
          classNameBlurHash,
          completeLoadingImage || completeLoadingVideo ? 'hidden' : 'block',
        )}
      />
    </div>
  );
};
export default Video;

import clsx from 'clsx';
import { Fragment } from 'react';

import ButtonLink from '@/components/ButtonLink/ButtonLink';
import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { ButtonLinkType } from '@/types/buttonType';
import { imageType } from '@/types/imageType';

export interface LargeImageSectionProps {
  button: ButtonLinkType;
  description: string;
  image: imageType;
  title: string;
  anchor?: string;
  textCenter?: boolean;
  titleMaxWidth?: number;
  descriptionMaxWidth?: number;
}

const LargeImageSection = ({
  image,
  button,
  title,
  description,
  anchor,
  textCenter,
  titleMaxWidth,
  descriptionMaxWidth,
}: LargeImageSectionProps) => {
  return (
    <GlobalWrapperSection
      classNameWrapper="bg-collapo-white"
      classNameInner="flex-wrap justify-center gap-y-10 sm:gap-y-15 pb-15 md:px-4"
      enabledMobilePaddingX={false}
      enabledMobilePaddingY={false}
      anchor={anchor}
    >
      <Fragment>
        <div className="relative h-[447px] w-full overflow-hidden sm:h-[520px]">
          <PictureWithBlurHash
            imgSrc={image.imgSrc}
            imgSrcMobile={image.imgSrcMobile}
            isPathMobile={!!image.imgSrcMobile}
            alt={image.alt}
            blurHash={image.blurHash}
            fullScreen
            classNameWrapper="h-[447px] w-full sm:h-[520px]"
          />
        </div>
        <div className="flex flex-col gap-y-10 px-4 sm:items-center sm:gap-y-8">
          <div
            className={clsx(
              'flex flex-col items-center space-y-6',
              textCenter ? 'text-center' : 'sm:text-center',
            )}
          >
            <div>
              <h2
                style={{ maxWidth: titleMaxWidth }}
                className="text-h2-mobile sm:text-h2"
              >
                {title}
              </h2>
            </div>
            <div>
              <p style={{ maxWidth: descriptionMaxWidth }} className="text-p">
                {description}
              </p>
            </div>
          </div>
          <div>
            <ButtonLink
              className="sm:max-w-max"
              href={button.path}
              ariaLabel={button.label}
              openInNewTab={button.openInNewTab}
            >
              {button.label}
            </ButtonLink>
          </div>
        </div>
      </Fragment>
    </GlobalWrapperSection>
  );
};

export default LargeImageSection;

import { useMemo } from 'react';

import Picture from '@/components/Picture/Picture';
import { imageType } from '@/types/imageType';

export type IconSpecType = {
  text: string;
  icon?: imageType;
};

export interface IconSpecsTableProps {
  items: IconSpecType[];
}

const IconSpecsTable = ({ items }: IconSpecsTableProps) => {
  const memoItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
    }));
  }, [items]);

  return (
    <ul className="space-y-[15px]">
      {memoItems.map(({ icon, text }, index) => (
        <li
          key={`memoItems-iconSpecsTable-${index}`}
          className="flex items-center gap-x-2 border-b-black/20 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-2"
        >
          {icon && (
            <Picture
              imgSrc={icon?.imgSrc}
              alt={icon?.alt}
              width={30}
              height={20}
              format="svg"
              classNameWrapper="w-[30px] h-5 shrink-0"
            />
          )}
          <span className="text-rimac-black text-p">{text}</span>
        </li>
      ))}
    </ul>
  );
};
export default IconSpecsTable;

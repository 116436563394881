import TwoImagesHeadlineGrid, {
  TwoImagesHeadlineGridProps,
} from '@/components/TwoImagesHeadlineGrid/TwoImagesHeadlineGrid';

const DynamicTwoImagesHeadlineGrid = ({
  title,
  cards,
  anchor,
  titleMaxWidth,
  descriptionMaxWidth,
}: TwoImagesHeadlineGridProps) => {
  return (
    <TwoImagesHeadlineGrid
      cards={cards}
      title={title}
      anchor={anchor}
      titleMaxWidth={titleMaxWidth}
      descriptionMaxWidth={descriptionMaxWidth}
    />
  );
};

export default DynamicTwoImagesHeadlineGrid;

import { Fragment } from 'react';

import {
  AboutUsContentFragment,
  HomeContentFragment,
  ManufacturerListFragment,
  ManufacturerPartnerContentFragment,
  ProductFragment,
  ReferenceContentFragment,
  ReferenceListFragment,
  ServiceContentFragment,
  SocialResponsibilityContentFragment,
} from '@/api';
import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import DynamicCarousel from '@/components/shared/DynamicCarousel';
import DynamicImage from '@/components/shared/DynamicImage';
import DynamicLargeImageSection from '@/components/shared/DynamicLargeImageSection';
import DynamicOurTeam from '@/components/shared/DynamicOurTeam';
import DynamicText from '@/components/shared/DynamicText';
import DynamicTextImageSection from '@/components/shared/DynamicTextImageSection';
import DynamicTwoImagesHeadlineGrid from '@/components/shared/DynamicTwoImagesHeadlineGrid';
import DynamicTwoVideosHeadlineGrid from '@/components/shared/DynamicTwoVideosHeadlineGrid';
import DynamicWorkflow from '@/components/shared/DynamicWorkflow';
import getCdlPublicId from '@/hooks/useCdlPublicId';

type Props = {
  content?:
    | AboutUsContentFragment
    | HomeContentFragment
    | ReferenceContentFragment
    | ManufacturerPartnerContentFragment
    | ProductFragment
    | SocialResponsibilityContentFragment
    | ReferenceListFragment
    | ManufacturerListFragment
    | ServiceContentFragment
    | null;
};
const DynamicContent = ({ content }: Props) => {
  return (
    <Fragment>
      {content?.content?.map((content, index) => {
        switch (content?.__typename) {
          case 'ComponentSharedOurTeam':
            return (
              <DynamicOurTeam
                key={`ComponentSharedOurTeam-${index}`}
                anchor={content?.anchor || ''}
                title={content?.sectionContent?.title || ''}
                text={content?.sectionContent?.content?.text}
                subtitle={content?.sectionContent?.subtitle || ''}
                item={(content?.item || []).map((item) => ({
                  name: item?.name || '',
                  position: item?.position || '',
                  image: {
                    imgSrc: getCdlPublicId(
                      item?.image?.img_desktop?.data?.attributes?.url || '',
                    ),
                    imgSrcMobile: getCdlPublicId(
                      item?.image?.img_mobile?.data?.attributes?.url || '',
                    ),
                    alt: item?.image?.alt || '',
                    blurHash:
                      item?.image?.img_desktop?.data?.attributes?.blurhash ||
                      '12312312',
                  },
                }))}
              />
            );
          case 'ComponentSharedLogoCarousel':
            return (
              <DynamicCarousel
                key={`ComponentSharedLogoCarousel-${index}`}
                title={content?.title || ''}
                anchor={content?.anchor || ''}
                button={
                  content?.button?.label
                    ? {
                        path: content?.button?.path || '',
                        label: content?.button?.label || '',
                      }
                    : undefined
                }
                item={(content?.item || []).map((item) => ({
                  link: {
                    label: item?.link?.label || '',
                    path: item?.link?.path || '',
                    openInNewTab: !!item?.link?.openInNewTab,
                  },
                  image: {
                    imgSrc: getCdlPublicId(
                      item?.image?.img_desktop?.data?.attributes?.url || '',
                    ),
                    imgSrcMobile: getCdlPublicId(
                      item?.image?.img_mobile?.data?.attributes?.url || '',
                    ),
                    alt: item?.image?.alt || '',
                    blurHash:
                      item?.image?.img_desktop?.data?.attributes?.blurhash ||
                      '12312312',
                  },
                }))}
              />
            );
          case 'ComponentSharedWorkflow':
            return (
              <DynamicWorkflow
                key={`ComponentSharedWorkflow-${index}`}
                anchor={content?.anchor || ''}
                title={content?.sectionContent?.title || ''}
                text={content?.sectionContent?.content?.text}
                subtitle={content?.sectionContent?.subtitle || ''}
                item={(content?.item || []).map((item) => ({
                  title: item?.title || '',
                  description: item?.description || '',
                }))}
              />
            );
          case 'ComponentUiTextContent':
            return (
              <GlobalWrapperSection
                classNameWrapper="bg-collapo-black-10"
                classNameInner="flex-wrap xl:px-10"
                enabledDesktopPaddingX={false}
              >
                <DynamicText
                  key={`ComponentUiTextContent-${index}`}
                  richText={content?.text}
                  parentIndex={index}
                />
              </GlobalWrapperSection>
            );
          case 'ComponentUiImage':
            return (
              <GlobalWrapperSection
                classNameWrapper="bg-collapo-black-10"
                classNameInner="flex-wrap xl:px-10"
                enabledDesktopPaddingX={false}
              >
                <DynamicImage
                  key={`ComponentUiImage-${index}`}
                  image={{
                    imgSrc: getCdlPublicId(
                      content?.img_desktop?.data?.attributes?.url,
                    ),
                    imgSrcMobile: getCdlPublicId(
                      content?.img_mobile?.data?.attributes?.url,
                    ),
                    alt: content?.alt || '',
                    blurHash:
                      content?.img_desktop?.data?.attributes?.blurhash ||
                      '12312312',
                  }}
                />
              </GlobalWrapperSection>
            );
          case 'ComponentSharedLargeImageSection':
            return (
              <DynamicLargeImageSection
                key={`DynamicLargeImageSection-${index}`}
                anchor={content?.anchor || ''}
                title={content?.title || ''}
                description={content?.description || ''}
                button={{
                  path: content?.button?.path || '',
                  label: content?.button?.label || '',
                }}
                image={{
                  imgSrc: getCdlPublicId(
                    content?.image?.img_desktop?.data?.attributes?.url,
                  ),
                  imgSrcMobile: getCdlPublicId(
                    content?.image?.img_mobile?.data?.attributes?.url,
                  ),
                  alt: content?.image?.alt || '',
                  blurHash:
                    content?.image?.img_desktop?.data?.attributes?.blurhash ||
                    '12312312',
                }}
              />
            );
          case 'ComponentSharedTwoImagesGrid':
            return (
              <DynamicTwoImagesHeadlineGrid
                key={`ComponentSharedTwoImagesGrid-${index}`}
                anchor={content?.anchor || ''}
                title={content?.title || ''}
                cards={(content?.imagesGridItems || []).map((item) => ({
                  title: item?.title || '',
                  text: item?.description || '',
                  image: {
                    imgSrc: getCdlPublicId(
                      item?.image?.img_desktop?.data?.attributes?.url || '',
                    ),
                    imgSrcMobile: getCdlPublicId(
                      item?.image?.img_mobile?.data?.attributes?.url || '',
                    ),
                    alt: item?.image?.alt || '',
                    blurHash:
                      item?.image?.img_desktop?.data?.attributes?.blurhash ||
                      '12312312',
                  },
                }))}
              />
            );
          case 'ComponentSharedTwoVideosGrid':
            return (
              <DynamicTwoVideosHeadlineGrid
                key={`ComponentSharedTwoVideosGrid-${index}`}
                anchor={content?.anchor || ''}
                title={content?.title || ''}
                cards={(content?.videosGridItems || []).map((item) => ({
                  title: item?.title || '',
                  text: item?.description || '',
                  video: {
                    posterAlt: item?.video?.poster_alt || '',
                    posterSrc: getCdlPublicId(
                      item?.video?.poster?.data?.attributes?.url || '',
                    ),
                    videoSrc: getCdlPublicId(
                      item?.video?.video?.data?.attributes?.url || '',
                    ),
                    blurHash:
                      item?.video?.poster?.data?.attributes?.blurhash ||
                      'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
                  },
                }))}
              />
            );

          case 'ComponentSharedTextImageSection':
            return (
              <DynamicTextImageSection
                key={`ComponentSharedTextImageSection-${index}`}
                anchor={content?.anchor || ''}
                title={content?.title || ''}
                text={content?.text}
                image={{
                  imgSrc: getCdlPublicId(
                    content?.image?.img_desktop?.data?.attributes?.url,
                  ),
                  imgSrcMobile: getCdlPublicId(
                    content?.image?.img_mobile?.data?.attributes?.url,
                  ),
                  alt: content?.image?.alt || '',
                  blurHash:
                    content?.image?.img_desktop?.data?.attributes?.blurhash ||
                    '12312312',
                }}
                iconSpecs={(content?.IconSpec || []).map((item) => ({
                  text: item?.text || '',
                  icon: {
                    imgSrc: getCdlPublicId(
                      item?.icon?.img_desktop?.data?.attributes?.url || '',
                    ),
                    imgSrcMobile: getCdlPublicId(
                      item?.icon?.img_mobile?.data?.attributes?.url || '',
                    ),
                    alt: item?.icon?.alt || '',
                    blurHash:
                      item?.icon?.img_desktop?.data?.attributes?.blurhash ||
                      '12312312',
                  },
                }))}
              />
            );
        }
      })}
    </Fragment>
  );
};

export default DynamicContent;

import clsx from 'clsx';
import Link from 'next/link';
import { Fragment } from 'react';

import ButtonLink from '@/components/ButtonLink/ButtonLink';
import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import { LinkProps } from '@/components/navigation/Navigation';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { useWindowSize } from '@/hooks/useWindowSize';
import { ButtonLinkType } from '@/types/buttonType';
import { imageType } from '@/types/imageType';

export interface CarouselSectionProps {
  backgroundColor?: 'light' | 'dark';
  title: string;
  anchor: string;
  button?: ButtonLinkType;
  item?: CarouselItemProps[];
}

export interface CarouselItemProps {
  image?: imageType;
  link: LinkProps;
}

export const Carousel = ({
  backgroundColor = 'light',
  item,
  anchor,
  button,
  title,
}: CarouselSectionProps) => {
  const { width } = useWindowSize();

  return (
    <GlobalWrapperSection
      classNameWrapper={clsx(
        backgroundColor === 'light' && 'bg-collapo-white',
        backgroundColor === 'dark' && 'bg-collapo-black-10',
      )}
      classNameInner="flex-wrap xl:px-10"
      enabledDesktopPaddingX={false}
      anchor={anchor}
    >
      <Fragment>
        <h2 className="mb-10 mt-8 text-h2 font-bold text-collapo-black sm:text-h2-mobile md:text-[40px]">
          {title}
        </h2>
        <div
          className={clsx(
            'carousel-container block',
            backgroundColor === 'light' && 'carousel-container-light',
            backgroundColor === 'dark' && 'carousel-container-dark',
          )}
        >
          <div className="carousel-track">
            {item?.map((item, index) => {
              if (!item || !item?.image?.imgSrc) return null;

              return (
                <Fragment key={`logoCarousel-home-first-${index}`}>
                  <Link
                    href={item?.link.path}
                    target={
                      width && width > 1024 && item?.link?.openInNewTab
                        ? '_blank'
                        : '_self'
                    }
                  >
                    <PictureWithBlurHash
                      imgSrc={item?.image?.imgSrc}
                      imgSrcMobile={item?.image?.imgSrcMobile}
                      isPathMobile={!!item?.image?.imgSrcMobile}
                      alt={item?.image?.alt}
                      blurHash={item?.image?.blurHash}
                      sizes="highResolution"
                      width={160}
                      height={160}
                    />
                  </Link>
                </Fragment>
              );
            })}
            {item?.map((item, index) => {
              if (!item || !item?.image?.imgSrc) return null;

              return (
                <Fragment key={`logoCarousel-home-second-${index}`}>
                  <Link
                    href={item?.link.path}
                    target={
                      width && width > 1024 && item?.link?.openInNewTab
                        ? '_blank'
                        : '_self'
                    }
                  >
                    <PictureWithBlurHash
                      imgSrc={item?.image?.imgSrc}
                      imgSrcMobile={item?.image?.imgSrcMobile}
                      isPathMobile={!!item?.image?.imgSrcMobile}
                      alt={item?.image?.alt}
                      blurHash={item?.image?.blurHash}
                      sizes="highResolution"
                      width={160}
                      height={160}
                    />
                  </Link>
                </Fragment>
              );
            })}
            {item?.map((item, index) => {
              if (!item || !item?.image?.imgSrc) return null;

              return (
                <Fragment key={`logoCarousel-home-third-${index}`}>
                  <Link
                    href={item?.link.path}
                    target={
                      width && width > 1024 && item?.link?.openInNewTab
                        ? '_blank'
                        : '_self'
                    }
                  >
                    <PictureWithBlurHash
                      imgSrc={item?.image?.imgSrc}
                      imgSrcMobile={item?.image?.imgSrcMobile}
                      isPathMobile={!!item?.image?.imgSrcMobile}
                      alt={item?.image?.alt}
                      blurHash={item?.image?.blurHash}
                      sizes="highResolution"
                      width={160}
                      height={160}
                    />
                  </Link>
                </Fragment>
              );
            })}
          </div>
        </div>
        {button && (
          <div className="my-10 flex w-full justify-center">
            <div className="w-full sm:w-fit sm:max-w-max">
              <ButtonLink
                href={button.path}
                ariaLabel={button.label}
                openInNewTab={button.openInNewTab}
              >
                {button.label}
              </ButtonLink>
            </div>
          </div>
        )}
      </Fragment>
    </GlobalWrapperSection>
  );
};

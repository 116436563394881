import clsx from 'clsx';
import { Fragment } from 'react';

import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import RichText, { RichTextProps } from '@/components/shared/RichText';
import { imageType } from '@/types/imageType';

export interface OurTeamProps {
  backgroundColor?: 'light' | 'dark';
  title: string;
  subtitle: string;
  text: RichTextProps[];
  anchor: string;
  item?: OurTeamItemProps[];
}

export interface OurTeamItemProps {
  name: string;
  position: string;
  image: imageType;
}

const OurTeam = ({
  backgroundColor = 'light',
  title,
  subtitle,
  text,
  anchor,
  item,
}: OurTeamProps) => {
  return (
    <GlobalWrapperSection
      classNameWrapper={clsx(
        backgroundColor === 'light' && 'bg-collapo-white',
        backgroundColor === 'dark' && 'bg-collapo-black-10',
      )}
      classNameInner="flex-wrap justify-center xl:px-10"
      enabledDesktopPaddingX={false}
      anchor={anchor}
    >
      <Fragment>
        <div className="flex w-full flex-wrap">
          <div className="w-full">
            <div className="mx-auto mb-15 max-w-[510px] text-center">
              {subtitle && (
                <span className="mb-2 block text-h6 font-semi-bold uppercase text-collapo-blue">
                  {subtitle}
                </span>
              )}
              {title && (
                <h2 className="mb-10 mt-8 text-h2 font-bold text-collapo-black sm:text-h2-mobile md:text-[40px]">
                  {title}
                </h2>
              )}
              {text.length > 0 && (
                <div className="flex flex-wrap justify-center gap-y-6">
                  <RichText
                    isCookieBar
                    items={text}
                    parentSection="ourTeam"
                    parentIndex={1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {item && item.length > 0 && (
          <div className="flex w-full flex-wrap justify-center gap-x-10 gap-y-6">
            {item?.map((itemOurTeam, index) => {
              return (
                <Fragment key={`ourTeam-${index}`}>
                  <div className="w-full md:w-1/2 xl:w-1/4">
                    <div className="mx-auto w-full max-w-[370px]">
                      <div className="relative overflow-hidden">
                        <div className="mt-5 h-[480px] w-[370px]">
                          <PictureWithBlurHash
                            imgSrc={itemOurTeam?.image?.imgSrc}
                            imgSrcMobile={itemOurTeam?.image?.imgSrcMobile}
                            isPathMobile={!!itemOurTeam?.image?.imgSrcMobile}
                            alt={itemOurTeam?.image?.alt}
                            blurHash={itemOurTeam?.image?.blurHash}
                            fullScreen
                            classNameWrapper="w-full h-[480px]"
                          />
                        </div>
                        <div className="absolute bottom-5 left-0 w-full text-center">
                          <div className="relative mx-5 overflow-hidden bg-collapo-white px-3 py-5">
                            <h3 className="whitespace-nowrap text-p font-semi-bold text-collapo-black">
                              {itemOurTeam.name}
                            </h3>
                            <p className="whitespace-nowrap text-h6 text-collapo-black">
                              {itemOurTeam.position}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </Fragment>
    </GlobalWrapperSection>
  );
};
export default OurTeam;

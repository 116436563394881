import TextImageSection, {
  TextImageSectionProps,
} from '@/components/TextImageSection/TextImageSection';

const DynamicTextImageSection = ({
  title,
  text,
  image,
  anchor,
  titleMaxWidth,
  iconSpecs,
}: TextImageSectionProps) => {
  return (
    <TextImageSection
      title={title}
      text={text}
      image={image}
      anchor={anchor}
      titleMaxWidth={titleMaxWidth}
      iconSpecs={iconSpecs}
    />
  );
};

export default DynamicTextImageSection;

import LargeImageSection, {
  LargeImageSectionProps,
} from '@/components/LargeImageSection/LargeImageSection';

const DynamicLargeImageSection = ({
  image,
  button,
  title,
  description,
  anchor,
  textCenter,
  titleMaxWidth,
  descriptionMaxWidth,
}: LargeImageSectionProps) => {
  return (
    <LargeImageSection
      button={button}
      description={description}
      image={image}
      title={title}
      anchor={anchor}
      textCenter={textCenter}
      titleMaxWidth={titleMaxWidth}
      descriptionMaxWidth={descriptionMaxWidth}
    />
  );
};

export default DynamicLargeImageSection;

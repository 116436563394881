import {
  Carousel,
  CarouselSectionProps,
} from '@/components/shared/Carousel/Carousel';

const DynamicCarousel = ({
  backgroundColor,
  title,
  anchor,
  button,
  item,
}: CarouselSectionProps) => {
  return (
    <Carousel
      backgroundColor={backgroundColor}
      title={title}
      anchor={anchor}
      button={button}
      item={item}
    />
  );
};

export default DynamicCarousel;

import { Fragment, useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { imageType } from '@/types/imageType';

export interface TwoImagesHeadlineGridProps {
  cards: {
    image: imageType;
    text: string;
    title: string;
  }[];
  title: string;
  anchor?: string;
  titleMaxWidth?: number;
  descriptionMaxWidth?: number;
}
const TwoImagesHeadlineGrid = ({
  title,
  cards,
  anchor,
  titleMaxWidth,
  descriptionMaxWidth,
}: TwoImagesHeadlineGridProps) => {
  const memoCards = useMemo(() => {
    return cards?.map((card) => ({
      ...card,
    }));
  }, [cards]);

  return (
    <GlobalWrapperSection
      anchor={anchor}
      classNameInner="flex-wrap items-center"
    >
      <div className="w-full">
        {title && (
          <div>
            <h2
              className="pb-8 text-h2-mobile md:pb-15 md:text-h2"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(title),
              }}
            />
          </div>
        )}
        <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-2 md:gap-5">
          {memoCards.map(({ image, title, text }, index) => (
            <div
              key={`memoCards-twoImagesHeadlineGrid-${index}`}
              className="flex w-full flex-col justify-between gap-y-5 md:gap-y-8"
            >
              <div className="space-y-4">
                <div>
                  <h3
                    style={{ maxWidth: titleMaxWidth }}
                    className="text-h3 leading-8"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(title),
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{ maxWidth: descriptionMaxWidth }}
                    className="text-p"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(text),
                    }}
                  />
                </div>
              </div>
              <div className="relative">
                <Fragment>
                  <PictureWithBlurHash
                    imgSrc={image.imgSrc}
                    imgSrcMobile={image.imgSrcMobile}
                    isPathMobile={!!image.imgSrcMobile}
                    alt={image.alt}
                    blurHash={image.blurHash}
                    fullScreen
                    classNameWrapper="h-[370px] w-full md:h-[481px]"
                    sizes="twoColumns"
                  />
                </Fragment>
              </div>
            </div>
          ))}
        </div>
      </div>
    </GlobalWrapperSection>
  );
};

export default TwoImagesHeadlineGrid;

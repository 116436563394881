import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import IconSpecsTable from '@/components/IconSpecsTable/IconSpecsTable';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import RichText, { RichTextProps } from '@/components/shared/RichText';
import { IconSpecType } from '@/types/iconSpecsTableType';
import { imageType } from '@/types/imageType';

export interface TextImageSectionProps {
  title: string;
  text: RichTextProps[];
  image: imageType;
  anchor?: string;
  titleMaxWidth?: number;
  iconSpecs?: IconSpecType[];
}

const TextImageSection = ({
  title,
  text,
  image,
  anchor,
  titleMaxWidth,
  iconSpecs,
}: TextImageSectionProps) => {
  return (
    <GlobalWrapperSection
      anchor={anchor}
      classNameInner="flex-wrap gap-y-5 md:gap-y-15 justify-center"
    >
      <div className="grid w-full grid-cols-1 gap-x-[58px] gap-y-5 lg:grid-cols-2 xl:grid-cols-[auto_670px]">
        <div className="flex w-full flex-col justify-center">
          <div className="pb-11 sm:pb-15">
            <h2
              className="whitespace-pre-wrap text-h2-mobile md:text-h2"
              style={{ maxWidth: titleMaxWidth }}
            >
              {title}
            </h2>
          </div>
          <div className="space-y-6 lg:space-y-15">
            <div className="space-y-4">
              <RichText
                items={text}
                parentSection="textImageSection"
                parentIndex={1}
              />
            </div>
            {iconSpecs && (
              <div>
                <IconSpecsTable items={iconSpecs} />
              </div>
            )}
          </div>
        </div>
        <div>
          <PictureWithBlurHash
            imgSrc={image.imgSrc}
            imgSrcMobile={image.imgSrcMobile}
            isPathMobile={!!image.imgSrcMobile}
            alt={image.alt}
            fullScreen
            classNameWrapper="min-h-[370px] md:min-h-[706px] w-full"
            blurHash={image.blurHash}
            sizes="oneColumn"
          />
        </div>
      </div>
    </GlobalWrapperSection>
  );
};
export default TextImageSection;

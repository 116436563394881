import OurTeam, { OurTeamProps } from '@/components/OurTeam/OurTeam';

const DynamicOurTeam = ({
  backgroundColor,
  title,
  subtitle,
  text,
  anchor,
  item,
}: OurTeamProps) => {
  return (
    <OurTeam
      backgroundColor={backgroundColor}
      anchor={anchor}
      title={title}
      subtitle={subtitle}
      text={text}
      item={item}
    />
  );
};

export default DynamicOurTeam;

import TwoVideosHeadlineGrid, {
  TwoVideosHeadlineGridProps,
} from '@/components/TwoVideosHeadlineGrid/TwoVideosHeadlineGrid';

const DynamicTwoVideosHeadlineGrid = ({
  title,
  cards,
  anchor,
  titleMaxWidth,
  descriptionMaxWidth,
}: TwoVideosHeadlineGridProps) => {
  return (
    <TwoVideosHeadlineGrid
      cards={cards}
      title={title}
      anchor={anchor}
      titleMaxWidth={titleMaxWidth}
      descriptionMaxWidth={descriptionMaxWidth}
    />
  );
};

export default DynamicTwoVideosHeadlineGrid;
